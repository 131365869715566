import { I18n } from '@aws-amplify/core';

let langRegionLocale = navigator.language || navigator.userLanguage; 

const IndexLabels = {
    en: {
        FIND_ME: 'You can find me here:',
        ABOUT_ME: 'Software Engineer specialized in web development, working in both sides, backend and frontend for a long time. Give me a challenge, I will do the rest',
        WORKING_WITH: 'I have been working mostly with:',
    },
    es: {
        FIND_ME: 'Puedes encontrarme aquí:',
        ABOUT_ME: 'Ingeniero de Software especializado en desarrollo web, tanto en backend como en frontend. Dame un reto, Yo haré el resto',
        WORKING_WITH: 'He estado trabajando mayormente con:',
    },
};

// "en_US" -> "en", "es_CL" -> "es", etc
const languageLocale = langRegionLocale.substring(0, 2);

I18n.setLanguage(languageLocale);
I18n.putVocabularies(IndexLabels);
const Localei18n = () => null;

export { Localei18n };

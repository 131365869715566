import React from 'react';
import { Container, Card, Image, Row, Col } from 'react-bootstrap'
import gtavoImg from '../assets/img/29103184.png'
import { I18n } from '@aws-amplify/core';
import { SiGmail, SiLinkedin, SiGithub, SiJava, SiJavascript, SiFirebase, SiNginx } from "react-icons/si";
import { FaNodeJs, FaDocker, FaReact, FaAws } from "react-icons/fa";

export default function Index() {
    return (
        <>
            <Container>
                <Container className="center-content">

                    <Image src={gtavoImg} rounded fluid />
                    <p>@Gustavo Saavedra</p>
                    <Card
                        bg={'light'}
                        key={1}
                        text={'dark'}
                        style={{ width: '18rem' }}
                        className="mx-auto"
                    >
                        <Card.Body>
                            <Card.Text>
                                {I18n.get("ABOUT_ME")}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Container>

                <Container className="center-content" style={{ padding: 20 }}>
                    {I18n.get("WORKING_WITH")}
                </Container>
                <Container className="center-content">
                    <Row>
                        <Col md>
                            <div className="item">
                              <SiJava color="grey" title="Java" size={40}/>
                              <span className="caption">Java</span>
                            </div>
                            <div className="item">
                              <SiJavascript color="grey" title="JavaScript" size={40} />
                              <span className="caption">JavaScript</span>
                            </div>
                            <div className="item">
                              <FaNodeJs color="grey" title="NodeJs" size={40} />
                              <span className="caption">NodeJs</span>
                            </div>
                            <div className="item">
                              <FaReact color="grey" title="ReactJs" size={40} />
                              <span className="caption">ReactJs</span>
                            </div>
                            <div className="item">
                              <FaDocker color="grey" title="Docker" size={40} />
                              <span className="caption">Docker</span>
                            </div>
                            <div className="item">
                              <FaAws color="grey" title="Aws" size={40} />
                              <span className="caption">Aws</span>
                            </div>
                            <div className="item">
                              <SiFirebase color="grey" title="Firebase" size={40} />
                              <span className="caption">Firebase</span>
                            </div>
                            <div className="item">
                              <SiNginx color="grey" title="Nginx" size={40} />
                              <span className="caption">Nginx</span>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="center-content" style={{ padding: 20 }}>
                    {I18n.get("FIND_ME")}
                </Container>
                <Container className="center-content">
                    <Row>
                        <Col md>
                          <div className="item">
                            <a href="mailTo:gtavo.saavedra@gmail.com">
                                <SiGmail color="grey" title="Email" size={40} /> 
                            </a>
                            <span className="caption">Email</span>
                          </div>
                          <div className="item">
                            <a href="https://www.linkedin.com/in/gustavo-saavedra-5952881b" target="_blank" rel="noreferrer">
                                <SiLinkedin color="grey" title="Linkedin" size={40} />
                            </a>
                            <span className="caption">Linkedin</span>
                          </div>
                          <div className="item">
                            <a href="https://github.com/gtavos" target="_blank" rel="noreferrer">
                                <SiGithub color="grey" title="Github" size={40} />
                            </a>
                            <span className="caption">Github</span>
                          </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}

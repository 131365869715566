import React from 'react';
import { Container } from 'react-bootstrap'

export default function Footer() {
    return (
        <>
            <Container className="container-white center-content footer" style={{ marginTop: 50 }}>
               <span>Copyright &copy; {new Date().getFullYear()}{" "}</span>
            </Container>
        </>
    );
}

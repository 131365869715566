import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Localei18n } from './components/i18n/Localei18n';
import Index from './views/Index';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <>
      <Localei18n />
      <Index />
      <Footer />
    </>
  );
}

export default App;
